@import "@angular/material/theming";
@import "./spinner.scss";
@include mat-core();

$main-color: #635ca8;
$main-color-hover: #524b95;
$main-color-darker: #2e2867;
$white: #fff;
$gray-color: #9ea2a7;
$blue-color: #3482f4;
$blue-hover-color: #116bf2;
$black-color: #10002b;
$open-gray: #efeff6;
$white: #fff;
$border-color: #d0d5de;
$auth-background: #fafbff;
$off-gray: #9196a1;
$gary: #747a88;

//PRIMARY THEME
$mat-primary: (
  main: $main-color,
  lighter: $main-color-hover,
  darker: $main-color-darker,
  200: #3d4fa1,
  // For slide toggle,
  contrast:
    (
      main: $white,
      lighter: $white,
      darker: $white,
    ),
);

$theme-primary: mat-palette($mat-primary, main, lighter, darker);

//ACCENT THEME
$mat-accent: (
  main: #cf484f,
  lighter: #f4c7db,
  darker: #cf484f,
  200: #cf484f,
  // For slide toggle,
  contrast:
    (
      main: $white,
      lighter: $white,
      darker: $white,
    ),
);

$theme-accent: mat-palette($mat-accent, main, lighter, darker);

// //WARN_THEME
$mat-warn: (
  main: #ff0000,
  lighter: #ffb3b3,
  darker: #ff0000,
  200: #ff0000,
  // For slide toggle,
  contrast:
    (
      main: $white,
      lighter: $white,
      darker: $white,
    ),
);

$theme-warn: mat-palette($mat-warn, main, lighter, darker);

$glamera-light-theme: mat-light-theme(
  $theme-primary,
  $theme-accent,
  $theme-warn
);

// $sia-dark-theme: mat-dark-theme($theme-primary, $theme-accent, $theme-warn);

// .lightMode {
//   @include angular-material-theme($sia-light-theme);
// }

// .darkMode {
//   @include angular-material-theme($sia-dark-theme);
// }

@include angular-material-theme($glamera-light-theme);

@import "./auto-registration-theme.scss";
@import "./business-details-theme.scss";

@mixin component-theme() {
  @include auto-registration();
  @include details-theme();
}

@include component-theme();
