// @import "~bootstrap/dist/css/bootstrap.css";

@import url("https://fonts.googleapis.com/css2?family=Tajawal:wght@400;500;700&display=swap");
@import "~@ng-select/ng-select/themes/default.theme.css";

@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import "../node_modules/@bryntum/calendar/calendar.stockholm.css";

@import "~bs-stepper/dist/css/bs-stepper.min.css";
@import "../node_modules/font-awesome-animation/css/font-awesome-animation.min.css";
@import "projects/auto-registration/src/lib/styles/styles.scss";

@import url("../node_modules/primeng/resources/themes/saga-blue/theme.css");
@import url("../node_modules/primeng/resources/primeng.min.css");

@import url("../node_modules/primeicons/primeicons.css");

@import "~@angular/material/prebuilt-themes/indigo-pink.css";

body {
  text-align: start;
  font-size: 14px;
  color: #777;
  background-color: #fff;
  font-family: "Tajawal", sans-serif;
}

// $MainColor:#9e457b;
// $MainColor: #5b73e8;
// $MainColor: #4e449e;
// $MainColor: #5b73e8;
// $MainColor: #7952b3;
// $MainColor:#3c4992;

// $MainColor:#333e7d;
$MainColor: #5856d6;
$RemoveColor: #ca0e0e;
$bg-active: #d2eeff;
$bg-filter: #f5f5f5;

.MainColor {
  background-color: $MainColor;
  color: #fff;
}

.wrapper {
  display: flex;
  width: 100%;
}

.text-end {
  text-align: end;
}

.text-start {
  text-align: start;
}

.btn-rounded {
  border-radius: 10em;
}

.myCustomModalClasMinWidth90 .modal-dialog {
  min-width: 90%;
}

.myCustomModalClasMinWidth50 .modal-dialog {
  min-width: 50%;
}

.myCustomModalClasMinWidth30 .modal-dialog {
  min-width: 30%;
}

.myCustomModalClasMinWidth45 .modal-dialog {
  min-width: 45%;
}

.myCustomModalClasMinWidth40 .modal-dialog {
  min-width: 40%;
}

.myCustomModalClasMinWidth70 .modal-dialog {
  min-width: 70%;
}

.myCustomModalClasMinWidth60 .modal-dialog {
  min-width: 60%;
}

.myCustomModalClasMinWidth80 .modal-dialog {
  min-width: 80%;
}

.myCustomModalClasMinWidth85 .modal-dialog {
  min-width: 85%;
}

.alertError {
  color: red;
}

.btn-submit {
  background-color: #000 !important;
  display: block;
  width: 100%;
  border-radius: 30px;
  box-shadow: none;
  color: #fff;
  font-size: 14px;
  text-transform: capitalize;
  height: 45px;

  i {
    font-size: 24px;
    color: #fff;
    padding: 0px 10px;
    vertical-align: middle;
  }
}

.btn-submit:hover {
  background-color: #5cad5f;
  color: #fff;
}

.btn {
  text-transform: capitalize;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  color: #000;
}

.btn-clear {
  color: #000 !important;
  background-color: #e6e6ff;
  padding: 8px 30px;
  box-shadow: none !important;
}

.btn-danger {
  color: #3f51b5;
  background-color: #e6e6ff !important;
  border-radius: 4px;
  box-shadow: none;
  padding: 10px 15px;
}

.btn-success {
  color: #fff;
  background-color: #5856d6 !important;
  border-radius: 4px;
  box-shadow: none;
  padding: 10px 15px;
}

.btn:focus {
  outline: none;
  box-shadow: none;
  border: none;
}

@media (min-width: 992px) {
  .card-dashboard {
    .modal-lg {
      max-width: 720px !important;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.btn-main {
  background-color: $bg-active;
  color: $MainColor !important;
  border-radius: 12px;
  padding: 10px 30px;
  font-weight: 600;
  font-size: 14px;
  margin: 5px !important;
  box-shadow: none;
}

.btn-modal-action:hover {
  color: $bg-active !important;
  background-color: $MainColor !important;
}

/*========= header navbar dashboard  ================*/
.headerDashboard {
  .navbar {
    padding: 0px !important;
    background-color: #fff;
    // background-color: #d2eeff;
    box-shadow: none;
    border-bottom: 1px solid #ddd;

    // box-shadow: 0 2px 4px rgb(15 34 58 / 12%);
    .btn-bars {
      margin: 0px;
      background: #fff;
      padding: 5px 10px;
      box-shadow: none;
      color: #9a99ff;
      border: none;
      border-radius: 0;
      font-size: 24px;
      margin-inline-end: 5px;
    }

    .btn-bars:active,
    .btn-bars:active:focus,
    .btn-bars:focus,
    .btn-bars:hover {
      outline: 0;
      box-shadow: none;
    }

    .btn-bars .fa {
      font-size: 21px;
    }

    .nav-item .nav-link {
      display: flex;
      align-items: center;
      padding: 8px 10px;
      font-size: 14px;
      color: #000;
      font-weight: 700;
      font-family: "Tajawal", sans-serif;
    }

    .nav-item .nav-link:hover {
      background-color: $bg-active;
      color: $MainColor;
    }

    .nav-item .nav-link.d-none {
      padding: 15px 10px;
    }
  }

  .dropdown-menu {
    left: initial;
    right: 0;
    margin: 0;
    padding: 0;
    border-radius: 12px;
    padding: 8px;
    border: 0px solid #fff;
    box-shadow: 0px 9px 32px #00000029;

    a {
      padding: 6px 15px;
      font-size: 13px;
      color: #5b73e8 !important;
      font-weight: 300;
      border-bottom: 0px solid #fff;
      background: #e6f1f8;
      border-radius: 6px;
      margin: 5px 0;
    }

    a:last-child {
      background-color: #ffd8d6;
      color: #f06363 !important;
    }

    a:hover {
      background: $MainColor;
      color: #fff !important;
    }
  }

  .navbar-brand {
    margin: 0px 0px;
    padding: 0px ​0px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    font-family: "Tajawal", sans-serif;
    font-weight: 600;
    color: #000;
    font-size: 14px;
    display: inline-flex;

    span {
      padding-top: 5px;
      display: block;
    }

    img {
      width: 45px;
      margin-top: 3px;
    }
  }

  .navbar-brand:hover {
    color: $MainColor;
  }

  .first-char {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    text-align: center;
    background-color: #9a99ff;
    padding: 2px;
    display: inline-block;
    margin: 5px 5px;
    color: #ffffff;
    font-size: 16px;
  }

  .first-char-sz-support {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    text-align: center;
    background-color: #fc6e6e;
    padding: 6px;
    display: inline-block;
    margin: 0 5px;
    color: #ffffff;
    font-size: 18px;
    font-weight: 600;
  }
}

.dropdown-menu {
  left: initial;
  right: 0;
  margin: 0;
  padding: 0;
  border-radius: 6px;
  padding: 8px !important;
  border: 0px solid #fff;
  box-shadow: 0px 9px 32px #00000029;

  a {
    padding: 8px 15px;
    font-size: 14px;
    color: #5b73e8 !important;
    font-weight: 300;
    border-bottom: 0px solid #fff;
    background: #e6f1f8;
    border-radius: 6px;
    margin: 5px 0 !important;
  }

  // a:last-child {
  //   background-color: #ffd8d6;
  //   color: #f06363 !important;
  // }
  a:hover {
    background: $MainColor;
    color: #fff !important;
  }
}

/*========= side bar  ================*/

@media (min-width: 768px) {
  .sidebar {
    display: initial;
  }

  .mdblock {
    display: initial !important;
  }
}

@media (min-width: 768px) {
  .sidebar.mob {
    display: none;
  }

  .btnMob {
    display: none;
  }
}

@media (max-width: 768px) {
  .sidebar.web {
    display: none;
  }

  .btnWeb {
    display: none;
  }

  .content {
    width: 100% !important;
  }

  .navbar-brand {
    display: initial !important;
  }
}

.sidebar.mob {
  z-index: 9;
  width: 100%;
  position: absolute;
}

.sidebar {
  box-shadow:
    0 2px 22px 0 rgba(0, 0, 0, 0.2),
    0 2px 30px 0 rgba(0, 0, 0, 0.35);
  width: 370px;
  position: relative;
  top: 0;
  left: 0;
  height: 100vh;
  overflow: auto;
  background: #fff;
  color: #777;
  transition: all 0.3s;

  .head {
    padding: 30px 10px;
    // background: #0c5cab;
    // border-bottom: 1px solid #9c9c9c;
    text-align: start;
    color: #fff;
    font-size: 26px;

    .media {
      img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        border: 0px solid #8c9bec;
      }

      h5 {
        font-size: 16px;
        font-weight: 400;
      }
    }
  }

  nav {
    a {
      display: block;
      padding: 8px;
      color: #777;
      font-size: 16px;
      color: #fff;
    }
  }
}

.selectedTab {
  background-color: $bg-active !important;

  i.fas,
  i.far,
  .name {
    color: $MainColor !important;
  }
}

// .sidebar::-webkit-scrollbar {
//   width: 5px;
// }
// .sidebar::-webkit-scrollbar-track {
//   background: #fff;
// }
// .sidebar::-webkit-scrollbar-thumb {
//   background: #999;
// }
// .sidebar::-webkit-scrollbar-thumb:hover {
//   background: $MainColor;
// }

/*=========  End Side Menu  ================*/
/*=========  content  ================*/
.content {
  margin-top: 50px;
  padding: 10px 0;
  min-height: calc(100vh - 51px);
  transition: all 0.3s;
  position: absolute;
  top: 0;
  right: 0;
  // z-index: 4;
  // background-color: #e8e8e8;
  // background-color: #f0f2f5;
  background-color: #fff;
  border-inline-start: 1px solid #ddd;
}

@media (min-width: 768px) {
  .content {
    width: calc(100% - 180px);
  }
}

@media (max-width: 768px) {
  .content {
    width: 100%;
  }
}

.open-close-container {
  border: 1px solid #dddddd;
  margin-top: 1em;
  padding: 20px 20px 0px 20px;
  color: #000000;
  font-weight: bold;
  font-size: 20px;
}

/*=========  breadcrumb - btn add new  ================*/
.breadcrumb {
  background-color: transparent;
  // box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: none;
  border-radius: 0px;
  padding: 6px;
  margin: 15px 0;
  position: relative;

  .breadcrumb-item {
    font-size: 18px;
    color: #000;
    // font-family: system-ui;
    font-family: "Tajawal", sans-serif;
    font-weight: 700;
    line-height: 39px;

    a {
      font-size: 18px;
      color: #000;
      // font-family: system-ui;
      font-family: "Tajawal", sans-serif;
      font-weight: 700;
      line-height: 39px;
    }
  }

  .breadcrumb-item.active {
    color: #000;
    font-weight: 700;
  }

  .breadcrumb-item+.breadcrumb-item {
    padding-left: 0px;
  }
}

.breadcrumb-item+.breadcrumb-item::before {
  padding: 0 5px;
}

.add-new-btn {
  position: absolute;
  right: 0;
  top: 0;
  margin: 5px 0px;
  padding: 10px 20px;
  font-size: 14px;
  text-transform: capitalize;
  background: $MainColor;
  color: #fff;
  border-radius: 3px;

  i {
    //display: none;
  }
}

.add-new-btn:hover {
  color: #fff;
  background-color: #495cba;
}

.add-new-btn-two {
  position: absolute;
  right: 15%;
  top: 0;
  margin: 5px 0px;
  padding: 10px 20px;
  font-size: 14px;
  text-transform: capitalize;
  background: $MainColor;
  color: #fff;
  border-radius: 3px;

  i {
    //display: none;
  }
}

.add-new-btn-two:hover {
  color: #fff;
  background-color: #495cba;
}

.add-new-btn-three {
  position: absolute;
  right: 30%;
  top: 0;
  margin: 5px 0px;
  padding: 10px 20px;
  font-size: 14px;
  text-transform: capitalize;
  background: $MainColor;
  color: #fff;
  border-radius: 3px;

  i {
    //display: none;
  }
}

.add-new-btn-three:hover {
  color: #fff;
  background-color: #495cba;
}

html[dir="rtl"] {
  .add-new-btn {
    position: absolute;
    left: 0;
    right: initial;
  }

  .add-new-btn-two {
    position: absolute;
    left: 15%;
    right: initial;
  }

  .add-new-btn-three {
    position: absolute;
    left: 30%;
    right: initial;
  }
}

.accept-requet-btn {
  /* position: relative;
  right: 0;
  top: 0; */
  margin: 0px 0px;
  padding: 10px 20px;
  font-size: 14px;
  /* text-transform: capitalize; */
  background: #449d44;
  color: #fff;
  border-color: #0e3f0e;
  border-radius: 3px;

  i {
    display: none;
  }
}

.accept-requet-btn:hover {
  color: #fff;
  background-color: #1c5a1c;
}

html[dir="rtl"] {
  .accept-requet-btn {
    position: absolute;
    left: 0;
    right: initial;
  }
}

.remove-btn {
  position: absolute;
  right: 0;
  top: 0;
  margin: 5px 0px;
  padding: 10px 20px;
  font-size: 14px;
  text-transform: capitalize;
  background: $RemoveColor;
  color: #fff;
  border-radius: 3px;

  i {
    display: none;
  }
}

.remove-btn:hover {
  color: #fff;
  background-color: #aa0808;
}

.remove-btn {
  position: absolute;
  left: 0;
  right: initial;
}

.btn-new {
  font-size: 12px;
  padding: 5px;
  height: 36px;
  display: block;
  width: 100%;
  text-transform: capitalize;
}

/*========= filter-card ================*/

.filter-card {
  margin-bottom: 10px;
  background: #fff;
  padding: 0px 0px 0px 0px;
  // border: 1px solid #ddd;
  // box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  position: relative;

  .input-group {
    margin-bottom: 15px;
    background: $bg-filter;
    border-radius: 30px;
    position: relative;
    overflow: hidden;
  }

  .search-input {
    border-inline-end-width: 0;
    background-color: $bg-filter;
    border: 1px solid $bg-filter;
    border-radius: 12px;
  }

  .search-btn {
    background: #fff;
    margin: 0;
    color: #000;
    box-shadow: none;
    padding: 12px 15px 10px;
    background-color: $bg-filter;
    border: 1px solid $bg-filter;
    border-radius: 0px;
    border-inline-start-width: 0;
  }

  .search-btn:hover {
    background: $MainColor;
    color: #fff;
  }

  .option-btn {
    width: 100%;
    margin: 0;
    height: 36px;
    padding: 5px 15px;
    border-radius: 4px;
    font-size: 14px;
    box-shadow: none;
    background: $bg-filter;
    font-weight: 600;
    color: #000;
    display: block;
    text-align: start;
    margin-bottom: 15px;
    text-transform: capitalize;
  }

  .btn-export {
    padding: 8px 15px;
    font-size: 13px;
    color: #5b73e8 !important;
    font-weight: 300;
    border: 0px solid #fff;
    background: #e6f1f8;
    border-radius: 12px;
    width: auto;
  }

  .btn-search {
    padding: 8px 15px;
    font-size: 13px;
    color: #5b73e8 !important;
    font-weight: 300;
    border: 0px solid #fff;
    background: #e6f1f8;
    border-radius: 12px;
  }

  .form-control {
    padding: 0.375rem 0.75rem;
    font-size: 14px;
    height: 36px;
    font-weight: 400;
    line-height: 1.5;
    color: #000;
    background-color: $bg-filter;
    border: 1px solid $bg-filter;
    border-radius: 4px;
    transition:
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }

  .btn-filter-action {
    font-size: 14px;
    background-color: #d2eeff;
    color: #5856de;
    font-weight: 600;
    margin: 0;
    padding: 8px 30px;
    display: block;
    box-shadow: none;
  }
}

/*========= filter-card ================*/

.report-filter {
  margin-bottom: 10px;
  background: #fff;
  padding: 15px;
  border: 1px solid #ddd;
  // box-shadow: 0 1px 2px rgb(95 95 95 / 20%);
  position: relative;

  .input-group {
    margin-bottom: 15px;
    background: $bg-filter;
    border-radius: 30px;
    position: relative;
    overflow: hidden;
  }

  .search-input {
    border-inline-end-width: 0;
    background-color: $bg-filter;
    border: 1px solid $bg-filter;
    border-radius: 12px;
  }

  .search-btn {
    background: #fff;
    margin: 0;
    color: #000;
    box-shadow: none;
    padding: 12px 15px 10px;
    background-color: $bg-filter;
    border: 1px solid $bg-filter;
    border-radius: 0px;
    border-inline-start-width: 0;
  }

  .search-btn:hover {
    background: $MainColor;
    color: #fff;
  }

  .option-btn {
    width: 100%;
    margin: 0;
    height: 36px;
    padding: 5px 15px;
    border-radius: 4px;
    font-size: 14px;
    box-shadow: none;
    background: $bg-filter;
    font-weight: 600;
    color: #000;
    display: block;
    text-align: start;
    margin-bottom: 15px;
    text-transform: capitalize;
  }

  .btn-export {
    padding: 8px 15px;
    font-size: 13px;
    color: #5b73e8 !important;
    font-weight: 300;
    border: 0px solid #fff;
    background: #e6f1f8;
    border-radius: 12px;
  }

  .btn-search {
    padding: 8px 15px;
    font-size: 13px;
    color: #5b73e8 !important;
    font-weight: 300;
    border: 0px solid #fff;
    background: #e6f1f8;
    border-radius: 12px;
  }

  .form-control {
    padding: 0.375rem 0.75rem;
    font-size: 14px;
    height: 36px;
    font-weight: 400;
    line-height: 1.5;
    color: #000;
    background-color: $bg-filter;
    border: 1px solid $bg-filter;
    border-radius: 4px;
    transition:
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }

  .btn-filter-action {
    font-size: 14px;
    background-color: #d2eeff;
    color: #5856de;
    font-weight: 600;
    margin: 0;
    padding: 8px 30px;
    display: block;
    box-shadow: none;
  }

  .btn-search {
    background: #5856d5;
    color: #fff;
    border-radius: 30px;
    padding: 10px 60px;
    margin: 15px 0 0px;
  }
}

/*========= End filter-card ================*/

label {
  text-transform: capitalize;
  font-size: 14px;
  color: #000;
  font-weight: 600;
}

.form-control {
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  height: 36px;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.form-control:focus {
  color: #495057;
  background-color: #f5f5f5;
  border-color: #ddd;
  outline: 0;
  box-shadow: none;
}

.form-control.check {
  height: 20px;
  margin: 5px 0;
}

.form-control.ng-invalid {
  border-right: 3px solid #ef7067;
}

// .form-control.ng-valid{
//     border-right: 4px solid green;
// }
.ng-valid[required],
.ng-valid.required {
  border-right: 3px solid #65cc69 !important;
  /* border-left: 0px!important; */
}

.ng-invalid[required],
.ng-invalid.required {
  border-right: 3px solid #ef7067 !important;
  /* border-left: 0px!important; */
}

.btnDrop {
  position: relative;

  .dropdown-menu {
    min-width: 100%;
    border: 1px solid #ccc;
    border-radius: 0;
    padding: 0px;
    margin: 0px;

    .dropdown-item {
      font-size: 14px;
      border-bottom: 1px solid #eee;
      display: block;
      width: 100%;
      padding: 8px 10px;
      color: #777;
    }

    .dropdown-item:hover,
    .dropdown-item:active {
      background-color: $MainColor;
      color: #fff;
    }
  }

  .btn-control {
    padding: 0.375rem 0.75rem;
    margin: 0;
    display: block;
    text-align: start;
    width: 100%;
    font-size: 14px;
    height: 36px;
    font-weight: 400;
    line-height: 1.5;
    color: #777;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 0px;
    box-shadow: none;
    text-transform: capitalize;
  }

  .btn-control:hover,
  .btn-control:focus {
    box-shadow: none;
  }

  .dropdown-toggle::after {
    display: none !important;
  }
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
  background: #fff;
  text-align: center;
  padding: 0;
  margin-bottom: 10px;

  .nav-item {
    width: 33.33%;

    .nav-link {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom: 0px solid #ddd;
      font-weight: 500;
      padding: 5px;
      font-size: 14px;
      color: #777;
      text-transform: capitalize;
    }

    .nav-link:hover {
      border: 0px solid #fff;
    }

    .nav-link.active {
      background-color: $MainColor;
      border-color: #fff;
      border: 1px solid $MainColor;
      color: #ffffff;
    }
  }
}

.formCard {
  background-color: #fff;
  // box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: none;
  padding: 5px;

  label {
    display: inline-block;
    margin-bottom: 0.5rem;
    font-size: 14px;
    color: #000;
    font-weight: 600;
    text-transform: capitalize;
  }
}

/*================= table-card  ============*/

.table-card {
  background-color: #fff;
  width: 100%;

  .table {
    margin: 0px;

    .btn-table-action {
      margin: 0;
      padding: 5px 10px !important;
      font-size: 13px;
      font-weight: 500;
      background-color: #fff;
    }

    .btn-table-action:hover {
      background-color: $MainColor !important;
      color: #fff !important;
    }
  }

  .table-head {
    background-color: #000;

    th {
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      padding: 10px;
    }
  }

  .table-head2 {
    background-color: #eee;

    th {
      color: #000;
      font-size: 14px;
      font-weight: 600;
      padding: 10px;
    }
  }

  .table td {
    color: #444;
    padding: 5px 8px;
    font-size: 14px;
    font-weight: bold;
  }

  .table th:last-child {
    width: 150px;
  }

  .btn.btn-sm {
    padding: 5px;
    font-size: 14px;
    background: #fff !important;
    box-shadow: none;
    color: #777 !important;
  }

  .btn.btn-sm2 {
    padding: 5px;
    font-size: 14px;
    background: $MainColor !important;
    box-shadow: none;
    color: #fffafa !important;
  }

  .btn.btn-sm3 {
    padding: 5px;
    font-size: 14px;
    background: #ca0e0e !important;
    box-shadow: none;
    color: #fffafa !important;
  }

  .btn.btn-sm:hover {
    background-color: $MainColor !important;
    color: #fff !important;
  }

  .btn.btn-sm2:hover {
    background-color: #5b73e8 !important;
  }

  .btn.btn-sm3:hover {
    background-color: #df4545 !important;
  }

  .first-char {
    background-color: #dfeef7;
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: #5b73e8;
    padding: 3px;
    font-weight: 600;
    margin-inline-end: 5px;
  }

  .first-char-sz-support {
    background-color: #dfeef7;
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: #e85b5b;
    padding: 3px;
    font-weight: 600;
    margin-inline-end: 5px;
  }

  .dropdown-toggle::after {
    display: none;
  }

  .btn {
    padding: 5px;
    font-size: 14px;
    background: #5856d6 !important;
    box-shadow: none;
    color: #fff !important;
  }
}

.btn-drop {
  background: #d2eeff;
  margin: 0;
  padding: 0;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  color: #5b73e8;
}

/*==== ltr ============*/

html[dir="rtl"] {
  .sidebar {
    right: 0;
    left: initial;
  }

  .content {
    left: 0;
    right: initial;
  }

  .card-header .btnColapse.collapsed:before {
    content: "\f104" !important;
    float: left !important;
    right: initial;
  }

  .card-header .btnColapseSub.collapsed:before {
    float: left !important;
    right: initial;
  }

  .card-header .btnColapse:before,
  .card-header .btnColapseSub:before {
    float: left !important;
  }

  .dropdown-menu {
    left: 10px;
    right: initial;
  }

  .btnPush {
    float: left !important;
  }

  .inputPush {
    width: 100px;
    float: left !important;
  }
}

html[dir="rtl"] {

  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  label,
  p,
  a,
  li {
    // font-family: "Cairo", sans-serif;
    font-family: "Tajawal", sans-serif;
  }
}

/*=============  pagination ====================*/

.pagination .page-item.active .page-link {
  background-color: $MainColor;
  box-shadow: none;
}

.pagination .page-item.active .page-link:hover {
  background-color: $MainColor;
}

.pagination .page-item .page-link:hover {
  background-color: $MainColor;
  color: #fff;
}

.pagination .page-item .page-link {
  border: 1px solid #eee;
}

/*====  loading =============================*/

.fa-spin {
  -webkit-animation: fa-spin 0.5s linear infinite;
  animation: fa-spin 0.5s linear infinite;
  color: $MainColor;
}

.fa-spin2 {
  -webkit-animation: fa-spin 2s linear infinite;
  animation: fa-spin 2s linear infinite;
  color: $MainColor;
}

/*======= ng-select =================*/

.ng-select .ng-select-container {
  color: #333;
  background-color: #f5f5f5;
  border-radius: 0;
  border: 1px solid #f5f5f5;
  min-height: 36px;
  align-items: center;
  height: 300px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background-color: #9e457b;
  border-radius: 0px;
  margin-right: 5px;
  color: #fff;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
  border-left: 0px solid #fff;
  border-right: none;
  background: rgba(0, 0, 0, 0.33);
}

.ng-select .ng-option[aria-disabled="true"] {
  background-color: #f06363; /* Change to a muted color */
  color: #ff0; /* Change the text color */
  cursor: not-allowed;
}

/*=================================*/
.switch {
  label {
    cursor: pointer;

    .lever {
      position: relative;
      display: inline-block;
      margin: 0 1rem;
      margin-right: 0.625rem;
      vertical-align: middle;
      content: "";
      background-color: #bbb;
      border-radius: 0.9375rem;
      width: 2.5rem;
      height: 0.9375rem;
      -webkit-transition: background 0.3s ease;
      transition: background 0.3s ease;
    }

    .lever:after {
      position: absolute;
      top: -0.1875rem;
      left: -0.3125rem;
      display: inline-block;
      content: "";
      background-color: #f1f1f1;
      border-radius: 1.3125rem;
      -webkit-box-shadow: 0 0.0625rem 0.1875rem 0.0625rem rgba(0, 0, 0, 0.4);
      box-shadow: 0 0.0625rem 0.1875rem 0.0625rem rgba(0, 0, 0, 0.4);
      width: 1.3125rem;
      height: 1.3125rem;
      -webkit-transition:
        left 0.3s ease,
        background 0.3s ease,
        -webkit-box-shadow 1s ease;
      transition:
        left 0.3s ease,
        background 0.3s ease,
        -webkit-box-shadow 1s ease;
      transition:
        left 0.3s ease,
        background 0.3s ease,
        box-shadow 1s ease;
      transition:
        left 0.3s ease,
        background 0.3s ease,
        box-shadow 1s ease,
        -webkit-box-shadow 1s ease;
    }

    input[type="checkbox"] {
      opacity: 0;
      width: 0;
      height: 0;
    }

    input[type="checkbox"]:checked+.lever {
      background-color: #dccfe2;
    }

    input[type="checkbox"]:checked+.lever:after {
      left: 1.5rem;
      background-color: $MainColor;
    }
  }

  [type="checkbox"]:not(:checked),
  [type="checkbox"]:checked {
    position: absolute;
    pointer-events: none;
    opacity: 0;
  }
}

/*=========================*/
.btnsTab {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  display: flex;
  overflow: auto;
  padding: 3px;
  background: #fff;

  .btn {
    margin: 0;
    border-radius: 0;
    font-weight: 500;
    box-shadow: none;
    padding: 10px 15px;
    font-size: 14px;
    background: #eee;
    color: #333;
    text-transform: capitalize;
    border-right: 2px solid #fff;
    white-space: nowrap;
    border-left: 2px solid #fff;
    position: relative;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    width: 20%;
    // margin: 0;
    // border-radius: 0;
    // font-weight: 500;
    // box-shadow: none;
    // padding: 10px 5px;
    // font-size: 14px;
    // background: #f9f9f9;
    // color: #777;
    // text-transform: capitalize;
    // border-bottom: 1px solid #eee;
    // white-space: nowrap;
    // position: relative;
  }

  .selectedTab {
    background-color: $MainColor !important;
    color: #fff;
  }
}

/*===== modal ==========*/
.modal-dialog .modal-content {
  border-radius: 30px;
  overflow: hidden;
}

.modal-header {
  background: #fff;
  border-bottom: 1px solid #ddd;
  border-top-left-radius: 30px !important;
  border-top-right-radius: 30px !important;

  .modal-title {
    font-size: 18px;
    color: #000;
    font-weight: 600;
  }

  .close {
    padding: 8px 15px;
    border-radius: 12px;
    margin: -5px 4px -10px auto;
    background: #f3f3f9;
    opacity: 1;
  }
}

.modal-body {
  background: #fff;
}

.modal-footer {
  justify-content: center;

  .btn.btn-send {
    background-color: #5856d6;
    border-radius: 12px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 30px;
  }

  .btn.btn-close {
    background-color: #d2eeff !important;
    color: #5856d6 !important;
    border-radius: 12px;
    padding: 10px 30px;
    font-weight: 600;
    font-size: 14px;
    margin: 5px !important;
    box-shadow: none;
  }

  .btn.btn-success,
  .btn.btn-danger {
    background-color: #d2eeff !important;
    color: #5856d6 !important;
    border-radius: 12px;
    padding: 10px 30px;
    font-weight: 600;
    font-size: 14px;
    margin: 5px !important;
    box-shadow: none;
  }

  .btn.btn-success:hover,
  .btn.btn-danger:hover {
    background-color: #5856d6 !important;
    color: #fff !important;
  }
}

.btn-modal-action {
  background-color: $bg-active;
  color: $MainColor !important;
  border-radius: 12px;
  padding: 10px 30px;
  font-weight: 600;
  font-size: 14px;
  margin: 5px !important;
  box-shadow: none;
}

.btn-modal-action:hover {
  color: $bg-active !important;
  background-color: $MainColor !important;
}

.modal .modal-fluid {
  width: 90%;
  max-width: 90%;
}

.btn-save {
  background-color: $MainColor;
  width: 100%;
  border-radius: 47px;
  /* background: red; */
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 10px;
}

html[dir="rtl"] {
  .modal-header {
    .close {
      margin: -5px auto -10px 5px;
    }
  }
}

/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';
/*=====  ============================*/

$mainColor: #4638b4;
$SecColor: #78dad1;

.BgHeader {
  // background-image: url("assets/img/bgheader.png");
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  position: relative;

  .Overlay {
    background-color: rgb(40 52 111 / 70%);
    height: 100%;
    padding: 150px 0 75px;
  }

  .slideText {
    text-align: center;
    color: #fff;

    h1 {
      font-weight: 700;
    }
  }
}

.FormHome {
  background-color: #fff;
  border-radius: 30px;

  .btn-rounded {
    background-color: $SecColor;
    padding: 10px 60px;
  }

  .form-control {
    font-size: 14px;
    height: 42px;
    background-color: #f8f8f8;
    border: 1px solid #eee;
  }
}

@import "../node_modules/angular-calendar/css/angular-calendar.css";

.cal-month-view .cal-header .cal-cell {
  padding: 10px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  background-color: #fff;
  color: #000;
  border: 1px solid #eee;
}

.cal-week-view .cal-day-headers {
  -js-display: flex;
  display: flex;
  padding-left: 50px;
  border: 1px solid;
}

html[dir="rtl"] {
  .cal-week-view .cal-day-headers {
    display: flex;
    padding-left: 0px;
    padding-right: 50px;
    background: #fff;
    color: #000;
    border: 1px solid #eee;
    margin-bottom: -4px;
    top: 100px;
    z-index: 999;
    position: sticky;
  }

  .cal-week-view .cal-day-headers .cal-header:first-child {
    border-left-color: #fff;
    border-right-color: #e1e1e1;
  }

  .cal-week-view .cal-day-headers .cal-header:first-child {
    border-left: 0px solid;
    border-right: solid 1px #ddd;
  }

  .cal-week-view .cal-day-column {
    flex-grow: 1;
    border-left: solid 0px;
    border-right: solid 1px #ccc;
  }

  .cal-month-view .cal-day-cell:not(:last-child) {
    border-left-color: #fff;
    border-right-color: #fff;

    border-right: 0px solid;
    border-left: 1px solid #eee;
  }

  .cal-week-view .cal-day-headers .cal-header {
    flex: 1;
    text-align: center;
    padding: 10px 3px;
    border-right: 1px solid #ddd !important;
    cursor: pointer;
  }

  .ml-auto {
    margin-left: 0 !important;
    margin-right: auto !important;
  }
}

.cal-week-view .cal-day-headers .cal-header:hover,
.cal-week-view .cal-day-headers .cal-drag-over {
  background-color: #f5f5f5;
  color: #000;
}

.cal-week-view .cal-header.cal-today {
  background-color: #d2eeff;
  color: #fff;
}

.cal-month-view .cal-cell-row .cal-cell:hover,
.cal-month-view .cal-cell.cal-has-events.cal-open {
  background-color: #5b73e8;
  color: #fff;
}

.cal-month-view .cal-day-badge {
  background-color: #5b73e8;
  color: #fff;
  float: left;
}

.cal-month-view .cal-open-day-events {
  color: #fff;
  background-color: #242526;
}

.cal-month-view .cal-day-cell.cal-today .cal-day-number {
  font-size: 1.9em;
  color: white;
  opacity: 1;
}

.cal-month-view .cal-day-cell.cal-today {
  background-color: #5b73e8;
}

// .cal-month-view .cal-day-cell:not(:last-child) {
//   border-right-color: #e1e1e1;
//   border-left-color: #e1e1e1;
// }
// .cal-month-view .cal-day-cell:not(:last-child) {
//   border-left: 1px solid;
//   border-right: 1px solid;
// }
.cal-week-view .cal-ends-within-day .cal-event {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.cal-week-view .cal-starts-within-day .cal-event {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

// .cal-week-view {
//   .cal-hour-segment.cal-after-hour-start .cal-time {
//       display: block;
//   }
// }

.cal-hour-segment.cal-after-hour-start {
  cursor: pointer;
}

// .cal-week-view .cal-time-events {
//   border-color: #e1e1e1;
//   border-top: 6px solid #5b73e8;
// }
.cal-week-view .cal-time-events .cal-event {
  width: calc(100% - 0px);
  height: calc(100% - 0px);
  margin: 0;
  padding: 0;
  line-height: 13px;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  border: 2px solid #eee;
}

.cal-week-view .cal-event,
.cal-week-view .cal-header {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
}

.cal-week-view .cal-day-headers span {
  font-weight: 400;
  opacity: 1;
}

.cal-week-view .cal-day-headers .cal-header {
  flex: 1;
  text-align: center;
  padding: 15px 5px;
  font-size: 14px;
  font-weight: 500;
  color: #333;
  // font-family: "Cairo", sans-serif;
  font-family: "Tajawal", sans-serif;
}

.cal-week-view .cal-event {
  color: #000;
  font-size: 14px;
  border: 0px solid;
  border-inline-start: 3px solid;
}

.IsWarning {
  background: white;
  text-align: center;
  padding: 80px 15px;

  i {
    // font-size: 150px;
    // color: #ffc107;

    font-size: 130px;
    color: #ffffff;
    background: #ffc107;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    padding: 50px;
    text-align: center;
  }
}

.input-group {
  .input-group-text {
    display: flex;
    align-items: center;
    padding: 5px 30px;
    margin-bottom: 0;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #f5f5f5;
    border: 1px solid #f5f5f5;
    border-radius: 0;
  }
}

.form-check-label {
  margin: 0px 15px;
}

html[dir="rtl"] {
  .treeview-item.treeview-item {
    margin-left: initial;
    margin-right: 2.5rem;
  }
}

.treeview-header {
  display: none !important;
}

.treeview-container {
  border: 1px solid #ddd !important;
}

.formgrouptbl .ng-select .ng-clear-wrapper {
  display: none;
}

.cal-day-view .cal-time {
  font-size: 11px;
  display: block !important;
}

.cal-week-view .cal-time {
  font-weight: 500;
  font-size: 14px;
  padding-top: 5px;
  width: 50px;
  text-align: center;
  background: #ffffff;
  margin: 0;
  height: 30px;
  border-bottom: 1px solid #eee;
  color: #777;
}

.cal-week-view .cal-time-label-column {
  width: 50px;
  height: 100%;
}

// .cal-current-time-marker {
//   height: 3px;
//   background: linear-gradient(270deg, #ec268d, #1f55dd);
//   background-size: 400% 400%;
//   animation: BackgroundFade 5s ease infinite;
//   z-index: 999999999;

//   @keyframes BackgroundFade {
//     0% {
//       background-position: 0 50%;
//     }
//     50% {
//       background-position: 100% 50%;
//     }
//     100% {
//       background-position: 0 50%;
//     }
//   }
// }

.btn-export {
  box-shadow: none !important;
  border: 1px solid #ddd;
  padding: 0px;
}

.btn-search {
  box-shadow: none !important;
  border: 1px solid #ddd;
  padding: 0px;
}

//  ========= ng select ==========
.ng-select.ng-select-single .ng-select-container {
  height: 36px;
  background-color: #f5f5f5 !important;
  border-radius: 30px;
  border-color: #f5f5f5;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: #000;
}

// .ng-invalid[required],
// .ng-invalid.required {
//   border-right: 1px solid #ddd !important;
// }

.ng-dropdown-panel.ng-select-bottom {
  box-shadow: 0px 9px 32px #00000029;
  border-radius: 30px;
}

.ng-dropdown-panel .scroll-host {
  border-radius: 15px;
}

.ng-dropdown-panel {
  background-color: #fff;
  border: 0px solid #ccc;
  box-shadow: 0px 9px 32px #00000029;
}

.ng-dropdown-panel .ng-dropdown-footer {
  border-top: 1px solid #ccc;
  padding: 10px 10px;
  text-align: center;
}

.dropdown-shift {
  right: 120px !important;
}

.custom-inp {
  width: 470px;
  height: 53px;
}

.custom-inp::placeholder {
  background-color: #ebf0fe !important;
  padding: 50px;
}

html[dir="rtl"] {
  .dropdown-shift {
    left: 0px !important;
    right: initial !important;
  }

  .for-hover {
    left: initial;
    right: 0px;
  }

  .SubCategory {
    left: initial;
    right: 180px;
  }

  .total-checkout {
    left: 0px;
    right: initial !important;
  }

  .card-payment {
    right: inherit !important;
    left: 0;
  }
}

.first-char {
  background-color: #eaeff3;
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: #5b73e8;
  padding: 2px;
  font-weight: 600 !important;
  -webkit-margin-end: 5px;
  margin-inline-end: 5px;
}

.first-char-sz-support {
  background-color: #eaeff3;
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: #eb3939;
  padding: 2px;
  font-weight: 600 !important;
  -webkit-margin-end: 5px;
  margin-inline-end: 5px;
}

.not-found {
  p {
    font-size: 16px;
    color: #000;
    font-weight: 600;
  }
}

.btblr {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.btbrr {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

html[dir="rtl"] {
  .btblr {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .btbrr {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}

html[dir="rtl"] {
  .mystyle {
    right: 60px !important;
  }
}

html[dir="ltr"] {
  .mystyle {
    left: 60px !important;
  }
}

.owl-theme .owl-nav [class*="owl-"] {
  border: 0px solid #ccc;
  color: #333;
  font-size: 14px;
  width: 30px;
  height: 30px;
  margin: 0px;
  padding: 4px 7px;
  background: #ffffff;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}

// .mystyle{
//   right:60px !important;
// }
// .modal-dialog.modal-xl {
//   max-width: 100% !important;
//   margin: 0px auto !important;
// }

// for register
.ng-select .ng-select-container {
  border-radius: 30px;
  height: 40px;
  background: #f4f5fa;
}

// .bshadow{
//   img{
//     width: 100px;
//   }
// }
.cal-current-time-marker {
  height: 3px;
  background: linear-gradient(270deg, #ec268d, #1f55dd);
  background-size: 400% 400%;
  -webkit-animation: BackgroundFade 5s ease infinite;
  animation: BackgroundFade 5s ease infinite;
}

.imgSpanSmall {
  margin: -1% 0% -5% 2%;
  min-width: 10%;
  display: inline-block;
  z-index: 9999;
}

.img-small {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  transition: transform 1.5s;
}

.navbar {
  font-weight: bold;
}

// .img-small:hover {
//   -ms-transform: scale(3.3);
//   -webkit-transform: scale(3.3);
//   transform: scale(3.3);
//   z-index: 99999;
// }
.img-small:hover .imgSpanSmall {
  position: absolute;
  z-index: 99999;
}

.img-small:hover .navbar {
  height: 50%;
}

.hoverable-small {
  position: relative;
  display: block;
  cursor: pointer;
  height: 35px;
  width: 35px;
  border: 0px solid #000;
  border-radius: 50%;
}

.hoverable-small .hover-text {
  position: absolute;
  display: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.hoverable-small .background {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.5);
  pointer-events: none;
  border-radius: 50%;
  z-index: 1;
}

.img-add-edit {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.hoverable-add-edit {
  position: relative;
  display: block;
  cursor: pointer;
  height: 200px;
  width: 200px;
  border: 5px solid #000;
  border-radius: 50%;
}

.hoverable-add-edit .hover-text {
  position: absolute;
  display: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.hoverable-add-edit .background {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.5);
  pointer-events: none;
  border-radius: 50%;
  z-index: 1;
}

.hoverable-add-edit:hover .hover-text {
  display: block;
}

.hoverable-add-edit:hover .background {
  display: block;
}

#fileInputAddEdit {
  display: none;
}

.visually-hidden {
  display: none;
}

.card-reports {
  .head-table {
    background-color: #ebeff2;
    border: 1px solid #ebeff2;
    padding: 15px;
    display: block;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    // margin-bottom: 15px;
  }

  .export {
    display: flex;
    align-items: end;
    justify-content: end;
    background: #e9ecef;

    .btn {
      height: 50px !important;
      height: initial;
      border-radius: 6px;
      width: 60px;
      margin: 7px 5px;
      padding: 5px 10px 0px 10px;
      box-shadow: none;
      font-size: 38px;
      color: #486d8a;
      font-weight: 700;
    }

    .btn:hover {
      background-color: #d2eeff;
    }

    .btn-excel {
      color: #52b856;
    }

    .btn-pdf {
      color: #e23f33;
    }
  }

  .table-row {
    padding: 0;
    margin: 30px 0;
  }

  .table-foot {
    background-color: #ebeff2;
  }
}

.slide-auth {
  .owl-theme .owl-nav.disabled+.owl-dots {
    margin-top: 10px;
    bottom: 15px;
    position: absolute;
    width: 100%;
  }

  .owl-theme .owl-dots .owl-dot span {
    width: 7px;
    height: 7px;
  }

  .owl-theme .owl-dots .owl-dot.active span {
    background: #eee;
    width: 30px;
    height: 7px;
  }
}

.countrycode {
  .ng-select.ng-select-single .ng-select-container {
    height: 51px;
    width: 100px;
    background-color: #fff !important;
    border-radius: 0px;
    border-color: #f5f5f5;
  }

  .ng-dropdown-panel .scroll-host {
    border-radius: 0px;
  }

  .ng-arrow-wrapper {
    display: none;
  }
}

.mobilecountrycode {
  .ng-select.ng-select-single .ng-select-container {
    width: 100px;
    background-color: #fff !important;
    border-radius: 0px;
    border-color: #f5f5f5;
  }

  .ng-dropdown-panel .scroll-host {
    border-radius: 0px;
  }

  .ng-arrow-wrapper {
    display: none;
  }
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: #000;
  text-align: center;
  margin-left: 40%;
}

.ngSelectClass {
  .ng-select.ng-select-single .ng-select-container {
    height: 36px;
    background-color: white !important;
    border-radius: 30px;
    border-color: white !important;
    box-shadow: none !important;
  }

  .form-control {
    font-size: 14px;
    height: 46px;
    border: 0px solid #fff;
    border-right-color: rgb(255, 255, 255);
    border-right-style: solid;
    border-right-width: 0px;
    // border-right: 0px solid #fff !important;
  }
}

.custom-inp {
  width: 320px !important;
  height: 55px !important;
}

/******************Ng Drop Down****************/
// .ng-dropdown-panel .scroll-host {
//   overflow: hidden;
//   overflow-y: auto;
//   position: relative;
//   display: block;
//   -webkit-overflow-scrolling: touch;
//   padding-bottom:400px;
// }

youtube-player>iframe {
  width: 100%;
  aspect-ratio: 16 / 9;
}

.ptr {
  cursor: pointer;
}

input[type="tel"] {
  display: none;
}

.custom-inp {
  display: block !important;
}

.custom-window-class-modal-handle-pay-booking .modal-dialog {
  max-width: 100%;
  min-width: 80%;
}

/* Importing Bootstrap SCSS file. */

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";

div[style*="pointer-events: auto !important; z-index: 2147483647 !important;"] {
  display: none !important;
}

.primeNG-sidebar-container {
  .p-sidebar-right{
    width: 40%;
  }

  .p-sidebar .p-sidebar-header {
    padding: 0;
  }

  .p-sidebar .p-sidebar-content {
    padding: 0;
  }

  .p-sidebar .p-sidebar-header .p-sidebar-close,
  .p-sidebar .p-sidebar-header .p-sidebar-icon {
    width: 56px;
    height: 56px;
    color: #2D3239;
    border: 0 none;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0px 4px 16px 0px #243C690A;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    position: absolute;
    left: -70px;
    top: 16px;
  }
}

.glamera-pro {
  .p-progressbar {
    height: 3px;
    background: linear-gradient(102.02deg, #908BEE 0%, #b2afff 98.82%) ;
    border-radius: 40px;

    .p-progressbar-value {
      background: #fff;
    }
  }
}
.admin-account-card {
  .p-progressbar {
    height: 3px;
    background: #b2afff;
    border-radius: 40px;

    .p-progressbar-value {
      background: #6938EF;
    }
  }
}


.primeNG-multiSelect-container {
  margin-top: 12px;
  width: 582px;
}

@media (max-width: 768px) {
  .primeNG-sidebar-container {
    .p-sidebar-right{
      width: 80%;
    }
  }
  .primeNG-multiSelect-container {
    width: 266px;
  }
}
// .custom-multi-select {
//   width: 100% !important;
//   max-width: 453px;
// }
.booking-item-number {
  font-weight: bolder;
  background: #fff;
  color: #000;
  padding: 2px;
  border-radius: 6px;
}


body.body-en {

  .siq_rht {
  position: fixed;
  right: 0 !important;
  left: unset !important;
}
.siq_bR {
  position: fixed;
  right: 10px !important;
  left: unset !important;
}
}

body.body-ar {
  .siq_rht {
    position: fixed;
    left: 0 !important;
    right: unset !important;
  }

  .siq_bR {
    position: fixed;
    left: 10px !important;
    right: unset !important;
  }
}
