@mixin auto-registration() {
  app-auto-registration {
    section {
      height: 100%;
      min-height: 100vh;
      overflow-y: hidden;
      max-width: 100vw;
      @media (max-width: 767px) {
        padding: 0 20px;
      }
      .slider {
        position: relative;
        background: url("../../../../../src/assets/img/signup.png") no-repeat
          center center;
        background-size: cover;
        background-color: $main-color;
        height: 100%;
        .overlay {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          background-color: rgba($color: #635ca8, $alpha: 0.2);
        }
        .video {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        main {
          margin-top: 20%;
          padding: 0 20%;
          img {
            width: inherit;
          }
          h1 {
            font-size: 40px;
            color: $white;
            margin-top: 25%;
          }
          p {
            margin: 0;
            margin-top: 5%;
            color: $gary;
            padding: 0;
            font-size: 20px;
            line-height: 30px;
          }
        }
      }
      .slider-ar {
        position: relative;
        background: url("../../../../../src/assets/img/signup_ar.png") no-repeat
          center center;
        background-size: cover;
        background-color: $main-color;
        height: 100%;
        .overlay {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          background-color: rgba($color: #635ca8, $alpha: 0.2);
        }
        .video {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        main {
          margin-top: 20%;
          padding: 0 20%;
          img {
            width: inherit;
          }
          h1 {
            font-size: 40px;
            color: $white;
            margin-top: 25%;
          }
          p {
            margin: 0;
            margin-top: 5%;
            color: $gary;
            padding: 0;
            font-size: 20px;
            line-height: 30px;
          }
        }
      }
      .slider-en {
        position: relative;
        background: url("../../../../../src/assets/img/signup.png") no-repeat
          center center;
        background-size: cover;
        background-color: $main-color;
        height: 100%;
        .overlay {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          background-color: rgba($color: #635ca8, $alpha: 0.2);
        }
        .video {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        main {
          margin-top: 20%;
          padding: 0 5%;
          img {
            width: inherit;
          }
          h1 {
            font-size: 40px;
            color: $white;
            margin-top: 25%;
          }
          p {
            margin: 0;
            margin-top: 5%;
            color: $gary;
            padding: 0;
            font-size: 20px;
            line-height: 30px;
          }
        }
      }

      .login-slider {
        background: url("../../../../../src/assets/img/login-bg.jpg") no-repeat
          center center;
        background-size: cover;
        .glamera_logo {
          margin: 2rem 3rem;
          cursor: pointer;
        }
      }

      form {
        padding: 40px 80px;
        overflow-y: auto;
        @media (max-width: 767px) {
          padding: 40px 25px;
        }
        main {
          padding: 40px 0;
          h1 {
            margin-bottom: 10px;
            font-weight: bold;
            font-size: 30px;
            span {
              color: $main-color;
              font-weight: bold;
            }
            img {
              display: block;
              margin: 0 10rem;
              @media (max-width: 767px) {
                display: none;
              }
            }
          }

          p {
            color: $gary;
            line-height: 22px;
            font-size: 15px;
          }
        }

        .submit-btn {
          padding: 5px 25px;
          @media (max-width: 767px) {
            margin-top: 10px;
          }
        }

        div {
          a {
            padding-bottom: 30px;
            margin-top: -10px;
            color: $blue-color;
            cursor: pointer;
            font-size: 15px;
            &:hover {
              color: $blue-hover-color;
            }
          }
          p {
            color: $gray-color;
            a {
              padding-bottom: 30px;
              color: $blue-color;
              cursor: pointer;
              font-size: 15px;
              &:hover {
                color: $blue-hover-color;
              }
            }
          }
        }

        .watch-video {
          cursor: pointer;
          .know-more {
            span {
              color: $gray-color;
              font-size: 12px;
            }
            p {
              font-size: 13px;
              color: $main-color;
              font-weight: 400;
            }
          }
        }

        .signUp {
          padding: 30px 0;
          p {
            font-size: 13px;
            color: black;
            font-weight: 400;
            a {
              font-size: 13px;
              padding: 0 5px;
              text-decoration: underline;
            }
          }
        }

        .business-type {
          p {
            color: $black-color;
            margin-bottom: 10px;
          }

          .groups {
            div {
              span {
                margin: 0 5px;
              }
            }
          }

          .b-type {
            cursor: pointer;
            img {
              // border-radius: 50%;
              width: 50px;
              height: 50px;
              object-fit: cover;
              // border: 2px solid $main-color;
            }
            span {
              font-size: 15px;
            }
          }
        }

        .know-us {
          padding: 30px 0;
        }

        .country {
          width: 100px;
          background-color: transparent;
          border: 1px solid $border-color;
          outline: none;
        }
      }
    }
    .verify-identity {
      form {
        background-color: $white;
        border-radius: 16px;
        padding: 40px;
        border: 1px solid #ccc;
        width: 500px;
        margin-top: 5%;
        @media (max-width: 767px) {
          width: 100%;
          padding: 40px 25px;
        }

        main {
          h1 {
            color: $black-color;
            text-align: center;
          }
          p {
            text-align: center;
          }
        }

        .type {
          border: 1px solid $border-color;
          border-radius: 5px;
          padding: 20px;
          margin-bottom: 30px;
          cursor: pointer;
          transition: all 0.5s;
          &:hover {
            padding: 25px;
          }
          div {
            .desc {
              margin: 0 10px;
              h1 {
                font-size: 16px;
                color: $black-color;
                font-weight: 400;
                margin-bottom: 3px;
                @media (max-width: 767px) {
                  font-size: 15px;
                }
              }
              p {
                font-size: 12px;
              }
            }
          }
        }

        a {
          font-size: 13px;
          text-decoration: underline;
          color: $blue-color;
          transition: all 0.5;
          &:hover {
            color: $blue-hover-color;
          }
        }
      }
    }

    .branch-list {
      padding-top: 4rem;
      padding-inline: 1rem;

      main {
        h1 {
          font-size: 24px;
          color: $black-color;
          text-align: center;
        }

        p {
          text-align: center;
          color: $gray-color;
          font-size: 16px;
          margin-top: 10px;
          font-weight: normal;
        }
      }

      .search {
        padding: 8px 0;
        position: relative;

        i {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);

          &.ltr {
            left: 0.5rem;
          }

          &.rtl {
            right: 0.5rem;
          }
        }

        input {
          width: 100%;
          padding: 20px 2rem;
          border: none;
          border-bottom: 1px solid #ccc;
          outline: none;
        }
      }

      &__content {
        width: 500px;
        @media (max-width: 767px) {
          width: 100%;
        }
        img {
          cursor: pointer;
        }
      }

      .branch-list__content {
        margin-bottom: 1rem;

        .branch-list-items {
          height: max(100vh - 330px, 400px);
          overflow-y: auto;

          app-branch-item {
            main {
              position: relative;

              .side-line {
                content: "";
                margin: 0;
                width: 0.25rem;
                height: 100%;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                background-color: #6ac7bb;

                &.ltr {
                  left: -1.5rem;
                }
                &.rtl {
                  right: -1.5rem;
                }
              }
            }
          }
        }
      }
    }

    .branch-item {
      padding: 1rem 1.5rem;
      border: 1px solid $border-color;
      border-radius: 10px;
      height: auto;
      min-height: auto;
      cursor: pointer;
      transition: all 0.5s;
      margin: 20px 0;
      &:hover {
        background-color: #f7f7f7;
      }
      main {
        padding: 0;
        img,
        .img {
          border-radius: 0.5rem;
          width: 4rem;
          max-height: 4rem;
          overflow: hidden;
          background-color: #fff;
        }

        & > div {
          padding: 0;
          margin: 0 10px;
          h1 {
            margin: 0;
            margin-bottom: 0.25rem;
            font-size: 15px;
            .is-main {
              color: $off-gray;
            }
          }

          p {
            margin: 0;
            margin-top: 5px;
            font-size: 12px;
          }
        }
      }
    }
  }

  .verification {
    padding: 40px;
    @media (max-width: 767px) {
      padding: 40px 10px;
    }
    .ngx-otp-input {
      width: 50px !important;
      height: 50px !important;
    }

    h1 {
      font-size: 16px;
      margin-top: 2rem;
    }

    p {
      font-size: 15px;
      color: $gray-color;
      margin-bottom: 30px;
      margin-top: 10px;
    }

    .desc {
      padding: 40px 0;
      border-bottom: 1px solid #ccc;
      h4 {
        color: #6ac7bb;
        font-size: 15px;
        font-weight: 400;
        span {
          color: black;
          padding: 0 5px;
          font-weight: 300;
          font-size: 12px;
        }
      }

      button {
        color: $off-gray;
        font-size: 14px;
        font-weight: 300;
        background-color: transparent;
        border: none;
        &:hover {
          color: $main-color-hover;
        }
        a {
          text-decoration: none;
          color: $off-gray;
        }
      }
    }

    a {
      font-size: 13px;
      color: #3482f4 !important;
      text-decoration: underline !important;
      cursor: pointer !important;
      padding-top: 20px !important;
      display: block !important;

      &:hover {
        color: $main-color-hover;
      }
    }
  }

  .reset-password,
  .verification {
    background: url("../../../../../src/assets/img/reset-bk.jpg") no-repeat
      center center;
    background-size: cover;
    form {
      position: relative;
      background-color: $white;
      box-shadow: 0px 4px 16px #243c6914;
      border-radius: 16px;
      padding: 70px 40px 40px;
      width: 500px;

      .close-btn-x {
        position: absolute;
        top: 50px;
        left: 40px;
        cursor: pointer;

        &:hover {
          color: #333;
        }
      }

      @media (max-width: 767px) {
        width: 100%;
        padding: 70px 25px 40px;

        .close-btn-x {
          left: 25px;
        }
      }

      main {
        padding: 0;
        margin-bottom: 30px;
        img {
          cursor: pointer;
        }
        p {
          font-weight: 200 !important;
        }
      }
      p {
        margin: 10px 0;
        font-weight: bold;
        line-height: 22px;
        font-size: 15px;
        color: #9196a1;
      }
      .span {
        color: $gray-color;
        font-size: 15px;
      }
      .different-account {
        margin-top: 30px;
        border-top: 1px solid #ccc;
        padding: 20px 0;
        h4 {
          font-size: 13px;
          color: #3482f4;
          cursor: pointer;
          transition: all 0.5s;
          font-weight: 200;
          &:hover {
            color: #186ded;
          }
        }
      }

      h6 {
        font-size: 12px;
        color: $gray-color;
        font-weight: 400;
      }
    }
  }

  .ngx-otp-input-container {
    box-shadow: none !important;
    background-color: transparent !important;
    border-radius: 0 !important;
    width: 100% !important;
  }

  .empty-text {
    text-align: center;
    font-size: 18px;
  }

  .mat-checkbox-inner-container {
    margin-top: 6px !important;
    font-weight: 300 !important;
  }
}
