/* You can add global styles to this file, and also import other style files */
// @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://fonts.googleapis.com/css2?family=Tajawal:wght@400;500;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Maven+Pro:wght@600&family=Poppins:wght@300;500&display=swa");
@import url("https://fonts.googleapis.com/css2?family=Maven+Pro:wght@600&family=Poppins:wght@300&display=swap");

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Poppins", "Tajawal", sans-serif, "Helvetica Neue";
  min-height: 100vh;
  display: grid;
}
* {
  padding: 0;
  margin: 0;
  font-family: "Poppins", "Tajawal", sans-serif;
  font-weight: 300;
}

input::placeholder {
  font-size: 12px;
}

:disabled {
  cursor: not-allowed;
}

@import "./variables.scss";

.mt-5 {
  margin-top: 5px;
}

.bt-1 {
  border-bottom: 1px solid #efeff6;
  padding-bottom: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.body-ar {
  direction: rtl;
}

.body-en {
  direction: ltr;
}

.gap-5 {
  gap: 5px;
}

.gap-30 {
  gap: 30px;
}

.gap-10 {
  gap: 10px;
}

.label {
  font-size: 14px;
  color: $black-color;
  padding-bottom: 5px;
}

.mt-30 {
  margin-top: 30px;
}

.mlr-30 {
  margin-right: 30px;
  margin-left: 30px;
}

.ptr {
  cursor: pointer;
}

.ngx-otp-input-container {
  padding: 0 !important;
}

.w-100 {
  width: 100%;
}

label {
  margin-bottom: 0 !important;
}
.mat-select-panel {
  margin-top: auto !important;
  margin-bottom: 0 !important;
  transform-origin: bottom !important;
}

.f14{
  font-size: 14px;
}