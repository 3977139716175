@mixin details-theme() {
  app-business-details {
    .mdc-form-field > label {
      margin: 0 !important;
      font-weight: 300;
    }

    .section {
      background-color: $auth-background;
      max-width: 100vw;
    }
    section {
      min-height: 100vh;
      .steps {
        background-color: $auth-background;
      }

      nav {
        padding: 4.5rem;
        position: relative;
        background: url("../../../../../src/assets/img/nav_bc.jpg") no-repeat
          center center;
        background-size: cover;
        height: 100%;
        ul {
          padding-top: 5rem;
          padding-left: 0;
          padding-right: 0;
          list-style: none;
          margin-bottom: 3rem;
          .active {
            color: $main-color-hover;
          }
          li {
            font-size: 20px;
            color: $gray-color;
            margin-bottom: 5%;
            cursor: pointer;
            transition: all 0.5s;
            &:hover {
              color: $main-color-hover;
            }

            div {
              margin-bottom: 2rem;
              span {
                border: 1px solid $gray-color;
                font-size: 18px;
                font-weight: bold;
                padding: 15px 20px;
                border-radius: 50%;
                color: $gray-color;
              }
              .active {
                border: 2px solid $main-color-hover;
                color: $main-color-hover;
              }
              img {
                width: 50px;
                height: 50px;
                margin-top: -12px;
                object-fit: contain;
              }
            }
          }
        }

        app-progress {
          position: absolute;
          bottom: 3rem;
          width: calc(100% - 9rem);
        }
      }

      .steps-progress {
        padding: 15px 25px;
        min-height: unset;
        border: 1px solid $main-color;
        border-radius: 10px;
        main {
          p {
            color: $black-color;
            margin: 0;
            font-weight: 400;
          }
        }

        .slider {
          width: 100%;
          padding: 0;
          .mdc-slider__input {
            pointer-events: none !important;
          }
        }
      }
    }

    form {
      padding: 40px;
      background-color: #fafbff;
      main {
        h1 {
          font-size: 16px;
          color: $gray-color;
          font-weight: 400;
          @media (max-width: 767px) {
            font-size: 9px;
            margin-top: 12px !important;
          }
          span {
            color: $main-color;
            font-weight: bold;
          }
        }
      }

      h1 {
        margin-top: 3rem !important;
        font-size: 20px;
      }

      p {
        color: $gray-color;
        font-size: 17px;
        font-weight: 300;
        span {
          color: $gray-color;
          font-weight: bold;
        }
      }

      .inputs {
        width: 70%;
        padding: 40px 0;
        @media (max-width: 767px) {
          width: 100%;
        }
        .spec-label {
          font-size: 15px;
          font-weight: 300;
          margin-bottom: 10px !important;
        }

        .checkBoxSpan {
          font-size: 14px;
          margin-top: 2px;
          display: block;
          font-weight: bold;
        }

        .services {
          padding: 20px 0;
          border-top: 1px solid #ccc;
          margin-top: 20px;
          div {
            .amount {
              width: 80px;
              padding: 8px 0;
              border: 1px solid #9b9c9e;
              background-color: #fff;
              margin-top: 5px;
              outline: none;
              text-align: center;

              &.ng-touched.has-error {
                border-color: #ff3547;
              }

              &:focus {
                border-color: #635ca8;
              }

              &:hover {
                border-color: #000;
              }

              &:disabled {
                border-color: #e7e7e7;
                background-color: #eceef2;
              }
            }
          }
          p {
            color: $main-color;
            font-size: 15px;
            text-align: center;
            margin-top: 20px;
            font-weight: bold;
            cursor: pointer;
          }
        }

        .all-days {
          background-color: $white;
          border: 1px solid $gray-color;
          padding: 10px 25px;
        }
        .normal-days {
          padding: 40px 22px;
          .day {
            margin-bottom: 20px;
          }
        }
        .time {
          background-color: $white;
          padding: 7px 15px;
          border: 1px solid $gray-color;
          border-radius: 8px;
        }

        .to {
          margin: 0 10px;
        }
      }

      .actions {
        width: 70%;
        @media (max-width: 767px) {
          width: 100%;
        }
        .skip {
          background-color: transparent;
          border: none;
          outline: none;
          font-size: 15px;
          cursor: pointer;
          transition: all 0.5s;
          padding: 7px 20px;
          margin: 0 30px;
          transition: all 0.5s;

          &:hover {
            color: $main-color;
          }
        }
        p {
          font-size: 15px;
        }
      }

      // .mdc-notched-outline {
      //   background-color: #ffffff;
      //   color: $gray-color;
      //   border: 1px soil $gray-color;
      // }
    }

    .plus {
      background-color: transparent;
      border: 1px solid $main-color;
      border-radius: 5px;
      padding: 11px 15px;
      margin-top: 5px;
      cursor: pointer;
      font-size: 20px;
      transition: all 0.5s;
      box-sizing: border-box;
      &:hover {
        background-color: $main-color;
        color: $white;
      }
      @media (max-width: 767px) {
        margin-bottom: 10px;
      }
    }

    .backIcon {
      width: 30px;
      height: 30px;
      cursor: pointer;
    }

    .custom-padding {
      padding: 5px 40px;
      @media (max-width: 767px) {
        padding: 5px 10px;
      }
    }
  }
}
